import styled from 'styled-components'

export const TransactionDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 85%;
`

export const ContainerDataTransaction = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px 8px 8px 8px;
  display: table-row;
  width: 100%;
  padding: 8px;
  margin-bottom: 1rem;
`

export const TitleDataTransaction = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-weight: bold;
  font-size: 14px;
`
export const DivRightDataTransaction = styled.div`
  text-align: end;
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-size: 13px;
`
export const DivLeftDataTransaction = styled.div`
  text-align: left;
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-size: 13px;
  font-weight: 600;
`

export const DivTableDataTransaction = styled.div`
  width: 50%;
`
export const DivContainerTable = styled.div`
  display: flex;
`
export const DivFooterDataTransaction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const TitleFooter = styled.div`
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-weight: 600;
  font-size: 16px;
  color: #1c0e49;
  margin-top: 8px;
  margin-bottom: 12px;
`
export const Space = styled.div`
  margin-top: 5px;
`

export const LinkEpayco = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${(props) => props.theme.colors.darkBlue100};
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;

  :hover {
    color: ${(props) => props.theme.colors.dark};
  }
`
