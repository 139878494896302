import React from 'react'
import styled from 'styled-components'
import { Spinner } from '../../assets/svg'
import { ButtonProps } from '../../types'
import { theme } from '../theme'

const { colors } = theme

const MainButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.disabled ? colors.white : props.color)};
  background-color: ${(props) => (props.disabled ? colors.grey30 : props.bg)};
  height: 2rem;
  width: 100%;
  outline: none;
  outline-style: none;
  border: ${(props) => `1px solid ${props.disabled ? colors.grey30 : props.color}`};
  border-radius: 4px;
  height: 2.5rem;
  font-family: ${(props) => props.theme.fonts.segeoUI};
  font-weight: bold;
  cursor: ${(props) => (props.disabled || props.loading ? 'auto' : 'pointer')};
  svg {
    fill: ${(props) => (props.disabled ? colors.white : props.color)};
    height: 2rem;
  }

  :hover {
    svg {
      fill: ${colors.white};
    }
    color: ${colors.white};
    background-color: ${(props) => (props.disabled ? colors.grey30 : colors.darkBlue110)}};
  }
`

export const Button = (props: ButtonProps) => {
  const { loading, typeBottom } = props

  const bg = {
    primary: loading ? colors.darkBlue110 : colors.dark,
    secondary: loading ? colors.darkBlue110 : colors.white,
  }

  const color = {
    primary: colors.white,
    secondary: loading ? colors.white : colors.darkBlue100,
  }
  return (
    <MainButton
      bg={typeBottom === 'secondary' ? bg.secondary : bg.primary}
      color={typeBottom === 'secondary' ? color.secondary : color.primary}
      {...props}
    >
      {props.loading ? <Spinner /> : props.children}
    </MainButton>
  )
}
