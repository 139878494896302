import { DefaultTheme } from 'styled-components'
import { config } from '../config/enviroment'
declare module 'styled-components' {
  export interface DefaultTheme {
    state: any
    images: {
      // banner: string,
      // bannerMovil: string,
      myEpaycoAdminPanel: string
      myEpaycoSecurity: string
      myEpaycoProfile: string
      myEpaycoShare: string
      myEpaycoHowToSendMoney: string
      myEpaycoCustomerService: string
      myEpaycoRateBackground: string
    }
    breakpoints: {
      xs: string
      sm: string
      md: string
      lg: string
      xl: string
    }
    fonts: {
      openSans: string
      kanit: string
      segeoUI: string
      lato: string
    }
    colors: {
      orangeEpayco: string
      darkBlue30: string
      darkBlue100: string
      darkBlue110: string
      grey15: string
      grey30: string
      grey60: string
      grey: string
      grey1: string
      grey3: string
      grey4: string
      white: string
      success: string
      pending: string
      rejected: string
      dark: string
    }
  }
}

export const theme: DefaultTheme = {
  state: '',
  images: {
    // banner: config.amazonUrl + '/my-epayco/img/my_epayco_banner.png',
    // bannerMovil: config.amazonUrl + '/my-epayco/img/my_epayco_banner_movil.png',
    myEpaycoAdminPanel: config.amazonUrl + '/my-epayco/img/my_epayco_dashboard.png',
    myEpaycoProfile: config.amazonUrl + '/my-epayco/img/my_epayco_profile.png',
    myEpaycoHowToSendMoney: config.amazonUrl + '/my-epayco/img/my_epayco_how_to.png',
    myEpaycoSecurity: config.amazonUrl + '/my-epayco/img/my_epayco_security.png',
    myEpaycoShare: config.amazonUrl + '/my-epayco/img/my_epayco_share.png',
    myEpaycoCustomerService: config.amazonUrl + '/my-epayco/img/my_epayco_customer_service.png',
    myEpaycoRateBackground: config.amazonUrl + '/my-epayco/img/my_epayco_rates_background.png',
  },
  breakpoints: {
    xs: '600px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1900px',
  },
  fonts: {
    openSans: 'Open Sans',
    kanit: 'Kanit',
    segeoUI: 'Segoe UI',
    lato: 'Lato',
  },
  colors: {
    orangeEpayco: '#FF6720',
    darkBlue30: '#62548F',
    darkBlue100: '#1C0E49',
    darkBlue110: '#080035',
    grey60: '#ADADAD',
    grey30: '#D3D3D3',
    grey15: '#F1F1F1',
    grey: '#EFEEF4',
    grey1: '#333333',
    grey3: '#828282',
    grey4: '#BDBDBD',
    white: '#FFFFFF',
    success: '#67C940',
    pending: '#FFD100',
    rejected: '#ED7C76',
    dark: '#1D1D1D'
  },
}
