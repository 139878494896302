import styled from 'styled-components'
import { THeaderBody, THeaderContainer } from '../../types/HeaderProps'

export const HeaderContainer = styled.section<THeaderContainer>`
  padding: 2rem 5%;
  background-color: ${(props) =>
    props.color ? `props.theme.colors.${props.color}` : props.theme.colors.darkBlue100};
  height: auto;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    ::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  > * {
    > * {
      z-index: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0rem;
    background-color: transparent;
    background-image: none;
  }
`

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    svg {
      width: 5rem;
    }
  }
`

export const HeaderLogo2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    svg {
      width: 5rem;
    }
  }
`

export const HeaderBody = styled.div<THeaderBody>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.justify ? 'center' : 'space-between')};
  padding: 0 5%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`
